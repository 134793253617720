import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image-es5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt,faEnvelope, } from '@fortawesome/free-solid-svg-icons'
import MapsEmbed from '../components/MapsEmbed';
import {Helmet} from 'react-helmet'

export default () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      graphCmsAsset(fileName: {eq: "contactBG.jpg"}) {
        id
        localFile{
          childImageSharp {
            fluid(maxWidth:1920,quality:90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      allGraphCmsProduct{
        nodes {
          productTitle
        }
      }
    }
  `) 



  console.log(data)
  return (
    <>
    <Helmet>
      <title>Contact Us | Great Pine Resources</title>
      <meta name="description" content="Call us at (+60)3 6242 8264 or e-mail us at wecare@greatpineresources.com.my" />
    </Helmet>
    <BackgroundImage
      className=''
      Tag="section"
      fluid={data.graphCmsAsset.localFile.childImageSharp.fluid}
      backgroundColor={`#fff`}>
    <div className='p-5 lg:p-20 flex justify-center w-screen'>
      <div  className=' bg-yellow-base rounded-xl p-2 lg:p-10' style={{maxWidth:'1500px'}}>

        <div className='w-full flex flex-row flex-wrap'>
          <div className='flex flex-col lg:w-1/2 p-3'>
            <p className='font-bold font-mont text-2xl m-1'>Locate Us</p>
            <MapsEmbed width="600" height="300" classNames="rounded-t-lg"/>
            <p className='bg-white p-3 pt-5 rounded-b-lg '>
            No. 9-1, Pusat Niaga Metro Prima,
            Jalan Prima 9, Metro Prima,
            Kepong, 52100, Kuala Lumpur     
            </p>
          </div>

          <div className='flex flex-col lg:w-1/2 p-3'>
            <div>
              <p className='text-2xl font-bold font-mont'>Contact Us</p>
              <span className='text-lg font-bold font-mont'>
                <div className='flex flex-row flex-nowrap items-center my-2'>
                  <FontAwesomeIcon icon={faPhoneAlt} className='mx-4 text-xl'/>
                  <a href='tel:+60362428264' className='text-2xl lg:text-4xl whitespace-nowrap'>(+60) 3 6242 8264 </a>
                </div>
                <div className='flex flex-row flex-nowrap items-center my-2'>
                  <FontAwesomeIcon icon={faEnvelope} className='mx-4 text-xl'/>
                  <a href='mailto:wecare@greatpineresources.com.my'>wecare@greatpineresources.com.my</a>
                </div>
              </span>
            </div>

            <div className='mt-6'>
              <p className='text-2xl font-bold font-mont'>Our Office Hours</p>
              <div className='text-lg mx-4'>
                <p>Mon-Fri:  9:00AM - 6:00PM</p>
                <p>Sat-Sun & Public Holidays: Off</p>
              </div>
            </div>
          </div>
        </div>

<hr style={{'border':'1px solid black', margin:'1.3rem'}}/>
<div className='text-center pb-5'>
  <span className=' text-xl lg:text-2xl font-bold font-mont'>Have an inquiry?</span>
</div>

<form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action='/inquirySubmitted'>   
  <input type="hidden" name="bot-field" />  
  <input type="hidden" name="form-name" value="contact" />  
  
  <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-2 lg:-mx-3 xl:-mx-3">

    <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
      <label htmlFor="name" >Name</label>
      <input type="text" name="name" id="name" placeholder='Name' className='form-input' pattern={`^[A-Za-z\s \@\&\'\(\)\/\,\.\:]+$`} title={`A-Z, @ & ( ) ' / , . : only`} required/>
    </div>

    <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
      <label htmlFor="email" >E-mail</label>
      <input type="email" name="email" id="email" placeholder='E-mail' className='form-input' title='Please enter a valid email' required/>
    </div>

    <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
      <label htmlFor="phone" >Phone Number</label>
      <input type="text" name="phone" id="phone" placeholder='Phone number' className='form-input' pattern={`^[0-9]*$`} title='Invalid contact number. Digits only.'required/>
    </div>

    <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-2 md:px-2 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
      <label htmlFor="company" >Company Name</label>
      <input type="text" name="company" id="company" placeholder='Company Name' className='form-input' pattern={`^[A-Za-z\s \@\&\'\(\)\/\,\.\:]+$`} title={`A-Z, @ & ( ) ' / , . : only`}/>
    </div>
  </div>

  <div className="my-3">
    <label htmlFor="inquiryType" >Inquiry Type</label>
    <select name="inquiryType" id="inquiryType" className='form-input mt-2' required>
          {
            data.allGraphCmsProduct.nodes.map(
              node => <option value={node.productTitle}>{node.productTitle}</option>
              )
          }
    </select>
  </div>
  <div  className="my-3">
    <label htmlFor="subject" >Subject</label>
    <input type="text" name="subject" id="subject" placeholder='Subject...' className='form-input mt-2'required/>
  </div>
  <div  className="my-3">
    <label htmlFor="content">Your Inquiry</label>
    <textarea name="content" id="content" placeholder='Your inquiry here...'className='form-input mt-2' rows="8"required/>
  </div>

  <div className='w-full flex flex-row justify-center'>
  <input type="submit" value="Send Message" className="rounded-lg p-5 m-2 bg-white text-black" />
  <input type="reset" value="Clear" className="rounded-lg p-5 m-2 bg-red-500 text-white"/>
  </div>
</form>
</div>
</div>
    </BackgroundImage>
    



    </>
  )
}