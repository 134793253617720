import React from 'react';
import {Link } from "gatsby"
import {Helmet} from 'react-helmet'
import Contact from './contact'

export default () => {
  return (
    <div>
      <Helmet>
        <title>Form Submitted!</title>
      </Helmet>
      <Contact className='absolute z-0  overflow-hidden'/>
    <div className='absolute w-full min-h-full overflow-hidden top-0 left-0  flex z-50 items-center justify-center'>
      <div className='p-10 text-center bg-white rounded-lg shadow-2xl'>
        <p>Thank you for your inquiry!</p>
        <p className='mb-4'>Our team will be contacting you soon.</p>
        <Link to='/contact' className='font-bold text-white bg-blue-800 py-3 px-5 m-2  rounded-lg underline'>Done</Link>
      </div>
    

    </div>
    </div>
  )
}